import React from 'react'

import blog from '../../../assets/css/blog.module.scss'
import mysite from 'mysite/assets/css/mysite.module.scss'

import { convertPathToS3 } from 'utils/tools'
import { BlogArticle } from '.'

export const A001_AdventCalendar2024: BlogArticle = {
  title: '美少女着ぐるみとIT技術',
  date: '2024/12/19',
  thumbnail: 'img/etc/230504cp.jpg',
  article: article(),
}

function article() {
  return (
    <div className={blog.articleContent}>
      <h3>アドベントカレンダーについて</h3>
      <p>
        この記事は、「着ぐるみ（kigurumi） Advent Calendar
        2024」Day19の記事です。
      </p>
      <p>
        <a href="https://adventar.org/calendars/10384" target="blank">
          https://adventar.org/calendars/10384
        </a>
      </p>
      <h3 id="はじめに">はじめに</h3>
      <p>こんにちは、とるめと申します。</p>
      <p>
        美少女着ぐるみの活動をしています。
        <br />
        そのほか、旅行やプログラミングなど、
        <br />
        様々な趣味を持っております。
      </p>
      <p>
        今回は、温羽とおさんが始めた
        <br />
        着ぐるみでアドベントカレンダーをやる試みに便乗して、
        <br />
        記事を書いてみることにしました。
      </p>
      <p>
        アドベントカレンダーとは、
        <br />
        もともとクリスマスまでの日数を数えるために、
        <br />
        おもちゃやお菓子を毎日ひとつずつ開けていく
        <br />
        海外のイベントです。
      </p>
      <p>
        その文化を受け継ぎ、Qiita などで
        <br />
        毎日1本記事を投稿する取り組みが生まれました。
        <br />
        それを美少女着ぐるみ活動でもやってみよう！という具合です。
      </p>
      <p>
        今回私はITっぽい記事を書いてみようと思います。
        <br />
        結果的に Qiita 風の記事に回帰しそうな感じになっています((
      </p>
      <p>
        こんな感じで、思いつくままに
        <br />
        書き綴っていこうと思います。
        <br />
        よろしくお願いいたします。
      </p>
      <p>
        早速色々書きましたが、お前何者だよって
        <br />
        思っている人もいると思うので、
        <br />
        簡単に筆者の経験を紹介します。
      </p>
      <ul>
        <li>情報学修士</li>
        <li>HTML / CSS / JavaScript 歴：8年</li>
        <li>C 歴：2年（ブランク除く）</li>
        <li>C++ 歴：2年（ブランク除く）</li>
        <li>Python 歴：6年</li>
        <li>TypeScript 歴：1年</li>
        <li>Go 歴：1年</li>
        <li>着ぐるみ愛好歴：20年</li>
        <li>着ぐるみROM専歴：7年</li>
        <li>着ぐるみ活動歴：3年</li>
      </ul>
      <h3 id="it技術とは">IT技術とは</h3>
      <p>
        IT ってよく聞く言葉ですけど、
        <br />
        実際何を指すんでしょうか？
      </p>
      <p>
        せっかくなので、今流行りの生成 AI の一つ、
        <br />
        ChatGPT くんに聞いてみましょう。
      </p>
      <div>
        <img src={convertPathToS3('img/blog/image-3.png')} alt="alt text" />
      </div>
      <div>
        <img src={convertPathToS3('img/blog/image-4.png')} alt="alt text" />
      </div>
      <p>言いたいこと全部言ってくれました。</p>
      <p>
        IT とは Information Technology の略で、
        <br />
        その名の通り「情報を使った技術」のことです。
      </p>
      <p>この世の中にはあらゆる「情報」があります。</p>
      <p>
        名簿や時刻表といったものから、
        <br />
        個人の趣味や天気に至るまで、
        <br />
        生物の行動に影響を与えるものは
        <br />
        すべて「情報」であると言えます。
      </p>
      <p>
        この情報をコンピュータの力を借りて
        <br />
        処理しようというのが、
        <br />
        一般的にイメージされる「IT」の役割です。
        <br />
        （とはいえ、厳密な定義は特に存在しないと思います。）
      </p>
      <p>
        <small>
          ちなみに、「IT技術」という言葉について少し補足します。
          <br />
          本来「IT」自体が「情報技術」を意味するため、
          <br />
          「IT技術」は「情報技術技術」となり、
          <br />
          厳密には適切な表現ではありません。
          <br />
          しかし、記事タイトルとのバランスを考慮し、
          <br />
          あえてこの表現を採用しています。
        </small>
      </p>
      <p>
        ここで、美少女着ぐるみの活動についても
        <br />
        ChatGPTくんに聞いてみましょう。
      </p>
      <p>
        <img src={convertPathToS3('img/blog/image-5.png')} alt="alt text" />
        <img src={convertPathToS3('img/blog/image-6.png')} alt="alt text" />
      </p>
      <p>どうでしょう？</p>
      <p>
        結構それっぽいこと言ってますし、
        <br />
        美少女着ぐるみ活動をやっている方も
        <br />
        共感できる部分は多いんじゃないでしょうか。
      </p>
      <p>
        個人的によく書いてあるなと思ったのは
        <br />
        「愛好者の注意点」に書いてある内容とかですかね。
        <br />
        この内容どこで知ったんだろうってくらい
        <br />
        解像度が高いように思います。
      </p>
      <p>
        逆に違うなと思ったのは「全身スーツ」のあたりです。
        <br />
        大きな間違いはないように思うのですが、
        <br />
        界隈人は絶対に「肌タイツ（肌タイ）」と言うはずですし、
        <br />
        素材の内容についても若干怪しいように思います。
      </p>
      <p>
        ちなみに、着ぐるみ趣味の中でも、
        <br />
        美少女系、ケモノ系、マスコット系等があり、
        <br />
        それぞれ少しずつ文化が違います。
        <br />
        今回は美少女系（もっと言うとアニメマスク系）に
        <br />
        絞って話を進めます。
      </p>
      <p>
        というわけで、IT技術に詳しくない人も、
        <br />
        美少女着ぐるみに詳しくない人も、
        <br />
        ChatGPT くんのおかげで
        <br />
        なんとなくのことを知れたと思います。
      </p>
      <h3 id="美少女着ぐるみ活動での事例">美少女着ぐるみ活動での事例</h3>
      <p>
        IT 技術と美少女着ぐるみについてそれぞれ見ましたが、
        <br />
        全然関係なさそうに見えますよね。
      </p>
      <p>
        それがどう融合するのか
        <br />
        イメージが湧かない人も多いと思います。
        <br />
        どのように IT 技術を美少女着ぐるみ活動に
        <br />
        取り入れられるのでしょう？
      </p>
      <p>
        自分がこれまでに試みたことや、
        <br />
        すでにある事例を見てみたいと思います。
      </p>
      <h4 id="創作活動の生成aiによるサポート">
        1. 創作活動の生成AIによるサポート
      </h4>
      <p>
        先ほどからお世話になっている ChatGPT を、
        <br />
        実はすでに個人的に活用していたりします。
      </p>
      <p>
        うちには「ささめ」という
        <br />
        オリジナルキャラクターがいるのですが、
        <br />
        ささめの設定を考える時のヒントを得るために
        <br />
        ChatGPT を活用していたりします。
      </p>
      <p>
        例えば、ささめがどんな趣味を持っているかの
        <br />
        設定を決めようとして、
        <br />
        以下のような質問をしました。
      </p>
      <p>
        <img src={convertPathToS3('img/blog/image-1.png')} alt="alt text" />
      </p>
      <p>
        他にも、YouTube に投稿する動画のタイトルを
        <br />
        考える時のヒントに使ったりもしました。
      </p>
      <p>
        例えば、えむが海辺の公園で散歩をするイメージの
        <br />
        動画のタイトルを決めようとして、
        <br />
        以下のような質問をしました。
      </p>
      <p>
        <img src={convertPathToS3('img/blog/image-2.png')} alt="alt text" />
      </p>
      <p>
        ここで提案してもらったタイトルは、
        <br />
        実際に以下の動画に反映されています。
      </p>
      <div className={mysite.youtubeIframe}>
        <iframe
          width="100%"
          src="https://www.youtube.com/embed/f0hRv5hR7sE"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <p>ChatGPT 以外にも AI を活用したことがあります。</p>
      <p>例えば、ささめのキャラクターデザインを決めたときの絵です。</p>
      <p>
        オリジナルキャラクターを発注したい！
        <br />
        でも絵を描く力も描いてくれる人脈もない！
        <br />
        ってなった時に、絵を自動生成してくれないかな
        <br />
        と思って見つけたのが以下のサイトです。
      </p>
      <p>
        <a href="https://waifulabs.com/" target="blank">
          https://waifulabs.com/
        </a>
      </p>
      <p>
        いくつかキャラクターの絵が表示されて、
        <br />
        その中から好みの絵を選んでいくと、
        <br />
        だんだんAIの力で自分好みのキャラクターの絵に
        <br />
        近づいていくというものです。
      </p>
      <p>で、辿り着いたのが以下の絵です。</p>
      <p>
        <img
          src={convertPathToS3('img/blog/cNeFJAF5_400x400.jpg')}
          alt="alt text"
          style={{ width: `80%` }}
        />
      </p>
      <p>
        見たことある人もいるでしょう。
        <br />
        今の雑多垢のアイコンにもなっています。
      </p>
      <p>
        この絵を工房に提出して、
        <br />
        工房の作品の特徴も加わって、
        <br />
        今のささめになりました。
      </p>
      <h4 id="kiglink">2. KIGLINK</h4>
      <p>これ、個人的に今気になっているものです。</p>
      <p>
        ケモノ系着ぐるみの方で
        <br />
        最初に「FURLINK」として始まったらしく、
        <br />
        オフやイベントで噂を耳にしていました。
      </p>
      <p>
        そして、先日イベントの「Kig Doll」に参加した際に
        <br />
        「KIGLINK」のビラをいただき、
        <br />
        ついにこの波が美少女系着ぐるみにも
        <br />
        来たか～と思いました。
      </p>
      <p>
        <a href="https://kiglink.me/" target="blank">
          https://kiglink.me/
        </a>
      </p>
      <p>
        <img src={convertPathToS3('img/blog/kiglink.png')} alt="alt text" />
      </p>
      <p>どういうものか簡単に説明します。</p>
      <p>
        公式には「プロフィールページ付きNFCタグ作成サービス」と説明されています。
      </p>
      <p>
        希望者は、このサービスを通じて「NFCタグ」を購入します。
        <br />
        そのタグには、自分のオリジナルページへの
        <br />
        リンクの情報を埋め込むことができます。
        <br />
        オリジナルページはカスタマイズ可能で、
        <br />
        ホームページやXなどのリンクやデザインを
        <br />
        自分好みに設定できます。
      </p>
      <p>
        そして、リンクを埋め込んだNFCタグは、
        <br />
        スマホでかざすだけで自分のページに
        <br />
        飛ぶことができるようになります。
      </p>
      <p>
        このNFCタグを、イベントやオフ会の時に
        <br />
        名札やカバンに仕込んでおくことで、
        <br />
        しゃべれない着ぐるみさんが自己紹介をするときに、
        <br />
        名刺の代わりとして使うことができます。
      </p>
      <p>
        きっとQRコードより楽に自分のページに飛べますし、
        <br />
        これによってコミュニケーションにかける
        <br />
        労力を減らすことができると思います。
      </p>
      <p>
        着ぐるみさんとのコミュニケーションに
        <br />
        新しい形が生まれそうな予感がしました。
      </p>
      <p>
        こういう形での技術の活用っていいですよね～
        <br />
        って思う、個人的にホットな事例でした。
      </p>
      <h4 id="kigudb">3. KiguDB</h4>
      <p>
        有志の方（主に Ringo_kig さんと思われます）によって作られた、
        <br />
        美少女着ぐるみの情報をまとめたデータベースです。
      </p>
      <p>ご存じの方も多いかもしれませんね。</p>
      <p>
        <a href="https://kigudb.info/" target="blank">
          https://kigudb.info/
        </a>
      </p>
      <p>
        キャラクターや所有者のアカウント名などで、
        <br />
        登録されている美少女着ぐるみを検索できます。
      </p>
      <p>
        また、知ってる着ぐるみさんで
        <br />
        登録されていないキャラクターがいたら
        <br />
        自分で登録申請することもできるようです。
        <br />
        自由に登録できる点にWikiっぽさを感じており、
        <br />
        これも良い文化だなと感じました。
      </p>
      <p>
        私も過去にデータベースサイトを運営していたことがあり、
        <br />
        美少女着ぐるみ活動でもこういったものを作って
        <br />
        楽しいことができたらなと感じていたりします。
      </p>
      <p>
        どういう技術を使ったらいいかなとか、
        <br />
        どうやって情報をまとめたらいいかなとか
        <br />
        考えるのが楽しいんですよね。
      </p>
      <p>
        しかし大変なことも色々あるなとも感じています。
        <br />
        こういうサービスを運営していく上のハードルは、
        <br />
        やはりいかにみんなに受け入れてもらえるかだと思います。
      </p>
      <p>
        特に多くの人を巻き込むようなサービスは、
        <br />
        信頼性や評判を大切にしていく必要があります。
      </p>
      <h3 id="これからやりたいこと">これからやりたいこと</h3>
      <p>ここまで、少しではありますが事例を見ていきました。</p>
      <p>
        もっと色々書きたかったのですが、
        <br />
        あまり詳しくなかったり、
        <br />
        書く時間が足りなかったりした関係で
        <br />
        書けなかったものが多いです。
      </p>
      <p>
        いつかどこかで語りたいなと思いつつ、
        <br />
        とりあえずざっと見出しだけ書いてみます。
      </p>
      <ul>
        <li>美少女着ぐるみでのAIを用いた音声合成によるボイスチェンジ</li>
        <li>3Dプリンタ向けの着ぐるみマスクのモデリングの簡素化</li>
        <li>モーションキャプチャーを用いて自分が演じた動きを外から観察</li>
        <li>画像認識で写真をキャラクターや撮影者ごとに自動分類</li>
      </ul>
      <p>
        などなどです。
        <br />
        （なんか論文の見出しっぽくなっているのはなぜ）
      </p>
      <p>
        私も未熟ではありますが、
        <br />
        せっかく技術的な経験があるし、
        <br />
        自分の成長の機会になりそうなので、
        <br />
        何か持っている技術を活かして、
        <br />
        自分やみなさんの活動に貢献出来たらな～
        <br />
        なんて考えています。
      </p>
      <p>
        例えば、この個人ホームページの制作も
        <br />
        その一環です。
      </p>
      <p>
        高校時代から趣味や部活などで
        <br />
        ホームページを作ってきた経験を活かして、
        <br />
        自分のホームページを作って
        <br />
        自分の活動やキャラクターの魅力を伝えたい！
        <br />
        と思ったのがきっかけです。
      </p>
      <p>
        このホームページも大切に
        <br />
        育てていきたいと考えています。
      </p>
      <p>
        その上で、何か新しい試みをしたいなと
        <br />
        考えていたりもします。
        <br />
        とはいえ、なかなかアイデアは
        <br />
        湧いてくるものではありません
      </p>
      <p>
        技術者やエンジニアあるあるなんですけど、
        <br />
        技術は持ち合わせているのに、
        <br />
        やりたいこと・作りたいものが
        <br />
        見つからないことって結構あるんですよね。
      </p>
      <p>
        もし、こんなものがあったら楽しそう！
        <br />
        こんなものがあったら便利そう！
        <br />
        と思うものを思いついた方は
        <br />
        ぜひ私にこれできる？って聞いてみてください！
      </p>
      <h3>さいごに</h3>
      <p>
        この記事のタイトルで何を書くことがあるんだ？と
        <br />
        思った方もいるかもしれません。
        <br />
        期待外れだった方もいるかもしれませんが、
        <br />
        とりあえず今書きたいことを書いてみました。
      </p>
      <p>
        IT技術を生かす余地は、
        <br />
        一見関係のなさそうな美少女着ぐるみ活動にも
        <br />
        意外とあるんだなと、
        <br />
        少しでも感じてもらえたら嬉しいです。
      </p>
      <p>
        そして、私自身も何かしてみたい！と思っているので、
        <br />
        随時アイデア募集中です。
        <br />
        何か作って皆さんに使ってもらう歓びを感じたいです。
      </p>
      <p>
        あまりにも難易度が高かったり
        <br />
        影響が大きかったりするものは
        <br />
        難しいかもしれませんが…
      </p>
      <p>
        といったところで、今回の記事はここまでです。
        <br />
        感想などお待ちしております！
      </p>
      <p>お読みいただきありがとうございました！</p>
    </div>
  )
}
